/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
    font-family: 'OpenSansLight';
    src: url("assets/fonts/OpenSans-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'OpenSansRegular';
    src: url("assets/fonts/OpenSans-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'PlayFairDisplay';
    src: url("assets/fonts/PlayfairDisplay-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'Quincy';
    src: url("assets/fonts/QuincyCF-ExtraBold.woff") format('woff');
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}

.maincontent {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  border-radius: 150px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-top: 125px;
  padding-left: 200px;
  padding-right: 200px;
  padding-bottom: 50px;
}

.background-gradient {
  --background: linear-gradient(108deg, rgba(255,255,255,1) 0%, rgba(199,221,204,1) 47%);
}

.footer-gradient {
  background: linear-gradient(108deg, rgba(255,255,255,1) 0%, #DAF1EC 47%) !important;
}

@media only screen and (max-width: 768px) {
  .maincontent {
    border-radius: 50px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}

ion-button {
  // --backround: var(--igive-color-btn);
  // --color: var(--igive-color-text);
  --background-hover: var(--igive-color-btn-light);
  --background-hover-opacity: 1;
  --color-hover: var(--igive-color-text) !important;
  // font-family: 'OpenSansRegular';
  // font-weight: 700;
}

// button:hover {
//   --background: #16123F;
//   background-color: #16123F;
//   color: white;
// }

a {
  color: #222;
}
